export const Features = (props) => {



  const appClick = (url)=>{
    console.log("clicked"+url)
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Our Products</h2>
        </div>
        <div className='row' style={{cursor: "pointer"}}>
          {props.data
            ? props.data.map((d, i) => (
                <div onClick={()=>appClick(d.link)} key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <img src={d.icon}></img>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
              
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
